import React from "react"

function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2rem"
      height="1.2rem"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 640 640"
    >
      <g className="icon">
        <path
          fillRule="nonzero"
          d="M199.715 241.137l205.833-101.564a118.488 118.488 0 01-1.984-21.72c0-65.282 52.936-118.22 118.218-118.22 65.292 0 118.23 52.938 118.23 118.218 0 65.292-52.938 118.218-118.23 118.218-25.808 0-49.702-8.256-69.155-22.3L236.27 320.533c.107 2.055.166 4.146.166 6.224 0 3.626-.177 7.217-.508 10.772l199.018 98.198c21.118-19.736 49.489-31.796 80.67-31.796 65.28 0 118.218 52.926 118.218 118.218 0 65.28-52.938 118.218-118.218 118.218S397.398 587.43 397.398 522.148c0-2.492.083-4.972.225-7.417L196.36 415.423c-20.835 18.378-48.178 29.551-78.143 29.551-65.292 0-118.23-52.937-118.23-118.218 0-65.292 52.938-118.218 118.23-118.218 31.595 0 60.296 12.402 81.497 32.6z"
        ></path>
      </g>
    </svg>
  )
}

export default ShareIcon
